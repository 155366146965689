<script setup lang="ts">
import type { Locale } from "@intlify/core-base"
const { trackEvent } = useAnalytics()
const { payload } = useNuxtApp()
const localisedCacheKeys = useLocalisedCacheKeys()

function handleSelect(selectedLanguage: string) {
  // If we don't do this, we'll only refresh data on current page. We want to refresh on all pages.
  // This it to fix this bug:
  // 1. Go to overview page
  // 2. Go to breaches page
  // 3. Change locale
  // 4. Go back to overview page
  // 5. Observe that featured videos have the wrong locale
  for (const key of localisedCacheKeys.value) {
    payload.data[key] = undefined
  }
  trackEvent("language_selected", {
    selected: selectedLanguage,
  })
}
function handleOpen() {
  trackEvent("language_previewed")
}

const { hasFeature } = useAuth()
const locales = computed<Locale[]>(() => {
  if (hasFeature("hub-locale-portuguese")) {
    return ["da_DK", "en_US", "de_DE", "nb_NO", "sv_SE", "pt_PT"]
  } else {
    return ["da_DK", "en_US", "de_DE", "nb_NO", "sv_SE"]
  }
})
</script>

<template>
  <LocaleSwitcher
    :locales
    @open-locale="handleOpen"
    @select-locale="handleSelect"
  />
</template>
